import { ExInterface,Request } from './index'

export default {
    /* 获取ip */
  findIp(data){
      return ExInterface({
          url: "https://ipinfo.io/json",
          method: "get",
          data: data
      })
  },
  /* 获取天气 */
  findWeather(data){
        return ExInterface({
            url: "https://api.oioweb.cn/api/weather.php",
            method: "get",
            data: data
        })
    },
}


