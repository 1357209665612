import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isRotation:true,  /* 轮播图是否显示 */
    isLeftShow:false,  /* 左侧栏是否显示 */
    lableName:'',   //标签名称
    titleName:'',  //单页titlem名称
  },
  mutations: {
    updateRotation(state,item){
      this.state.isRotation = item
    },
    updateLeftShow(state,item){
      this.state.isLeftShow = item
    },
    updatelabelName(state,item){
      this.state.lableName=item
    },
    updatetitleName(state,item){
      this.state.titleName = item
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})
