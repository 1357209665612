import {Request} from "./index";

export default {
    /* 添加留言 */
    addMesage(params){
        return Request({
            url: "/front/message/addMessage",
            method: "post",
            data: params
        })
    },
    /* 查询所有留言*/
    findAllMessage(params){
        return Request({
            url: "/front/message/findAllMessage",
            method: "post",
            data: params
        })
    },
    /* 添加回复留言*/
    addReplyMessage(params) {
        return Request({
            url: "/front/message/addReplyMessage",
            method: "post",
            data: params
        })
    }
}



