<template>
  <div id="app">

        <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'app',
  }
</script>

<style lang="scss">
    .ass{
        position: fixed;
        width: 100%;
        height: 100%;
    }
</style>
