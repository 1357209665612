import {put, Request} from './index'

export default {
    /* 获取总览数据 */
      findOverview(){
          return put({
              url: "/front/home/findOverview",
              method: "get",
              data: null
          })
      },

    /* 获取所有标签 */
    findLabelAll(){
        return put({
            url: "/front/home/findLabelAll",
            method: "get",
            data: null
        })
    },

    /* 获取最新留言 */
    findNewMessage(){
        return put({
            url: "/front/home/findNewMessage",
            method: "get",
            data: null
        })
    },
    /* 获取说说列表 */
    listList(){
        return put({
            url: "/front/life/lifeList",
            method:"get",
            data : null
        })
    },
    /* 获取照片列表 */
    photoList(){
        return put({
            url: "/front/life/photoList",
            method:"get",
            data : null
        })
    },
    /* 获取友链列表 */
    friendList(){
        return put({
            url: "/front/life/friendList",
            method:"get",
            data : null
        })
    },
    /* 获取博文列表 */
    blogList(params){
        return Request({
            url: "/front/life/blogList",
            method:"post",
            data : params
        })
    },

    /*获取单个博文信息*/
    findBlogByid(params){
        return Request({
            url: "/front/life/findBlogById",
            method:"post",
            data : params
        })
    },

    /* 根据博文获取评论 */
    findAllBlogComment(params){
        return Request({
            url: "/front/blog/findarticleComment",
            method:"post",
            data : params
        })
    },

    /* 添加评论 */
    addMianComment(params){
        return Request({
            url: "/front/blog/addBlogcomment",
            method:"post",
            data : params
        })
    },

    /* 添加子评论 */
    addBlogreply(params){
        return Request({
            url: "/front/blog/addBlogreply",
            method:"post",
            data : params
        })
    },

    /* 点赞 */
    addlikes(params){
        return Request({
            url: "/front/blog/addBlogLikes",
            method:"post",
            data : params
        })
    },

    /* 浏览 */
    addBolgviews(params){
        return Request({
            url: "/front/blog/addBolgviews",
            method:"post",
            data : params
        })
    },

    /* 获取日志 */
    findJonrual(params){
        return Request({
            url: "/front/life/findJonrual",
            method:"post",
            data : params
        })
    },

    /* 获取天气 */
    findWeather(params){
        return Request({
            url: "/front/home/findWeather",
            method:"post",
            data : params
        })
    },

    /* 获取最新的博文图片 */
    findHomeImg(){
        return Request({
            url: "/front/home/findHomeImg",
            method:"get",
            data : null
        })
    }
}


