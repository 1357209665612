import axios from 'axios'
import Vue from 'vue'
import qs from 'qs'

Vue.use(qs)

function Request(params){
    axios.defaults.baseURL = 'http://fuqz.cc:8082';
      return  new Promise((resolve, reject) => {
          axios({
              method: params.method,
              url: params.url,
              data: qs.stringify(params.data)
          }).then(res => {
              resolve(res.data)
          }).catch(err => {
              Vue.prototype.$message.error("服务器错误")
          })
      })
}
function ExInterface(params){
    axios.defaults.baseURL = ''
    return  new Promise((resolve, reject) => {
        axios({
            method: params.method,
            url: params.url,
            // data: qs.stringify(params.data)
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            Vue.prototype.$message.error("服务器错误")
        })
    })
}


function put(params){
    axios.defaults.baseURL = 'http://fuqz.cc:8082';
    return  new Promise((resolve, reject) => {
        axios({
            method: params.method,
            url: params.url,
            data: params.data
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            Vue.prototype.$message("服务器错误")
        })
    })
}

function del(params){
    return  new Promise((resolve, reject) => {
        axios({
            method: params.method,
            url: params.url+'/'+params.data,
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            Vue.prototype.$message("服务器错误")
        })
    })
}

export{
    put,
    Request,
    del,
    ExInterface
}
