import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//路由懒加载
const index = () =>import('../views/index')
const headerImg = () =>import('../views/header/headerImg')
const friendImg = () =>import('../views/header/friendImg')
const lifeImg = () =>import('../views/header/lifeImg')
const photoImg = () =>import('../views/header/photoImg')
const aboutImg = () =>import('../views/header/aboutImg')
const messageImg = () =>import('../views/header/messageImg')
const articleImg = () =>import('../views/header/articleImg')
const blogpostImg = () =>import('../views/header/blogpostImg')
const journalImg = () =>import('../views/header/journalImg')

const defaultContent = () => import("../views/home/defaultContent")
const friendContent = () =>import('../views/home/friendContent')
const lifeContent = () =>import('../views/home/lifeContent')
const photoContent = () =>import('../views/home/photoContent')
const aboutContent = () =>import('../views/home/aboutContent')
const messageContent = () =>import('../views/home/messageContent')
const articleContent = () =>import('../views/home/articleContent')
const blogpostContent = () =>import('../views/home/blogpostContent')
const journalContent = () =>import('../views/home/journalContent')

//切换多次的时候不报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}


const routes = [
  {
    path:"/",
    component:index,
    children:[
      {
        path:"/",
        redirect:"/headerImg",
      },
      /* 跳转首页*/
      {
        path:'/headerImg',
        components:{
          default:headerImg,
          rightbody:defaultContent
        },
        meta: {
          title:"FQZの客栈"
        }
      },
      /* 跳转友链 */
      {
        path:'/friendImg',
        name:"friendImg",
        components:{
          default:friendImg,
          rightbody:friendContent
        },
        meta: {
          title:"友链-FQZの客栈"
        }
      },
      /* 跳转说说 */
      {
        path:'/lifeImg',
        name:"lifeImg",
        components:{
          default:lifeImg,
          rightbody:lifeContent
        },
        meta: {
          title:"说说-FQZの客栈"
        }
      },
      /* 跳转照片墙 */
      {
        path:'/photoImg',
        name:"photoImg",
        components:{
          default:photoImg,
          rightbody:photoContent
        },
        meta: {
          title:"照片墙-FQZの客栈"
        }
      },
      /* 关于我 */
      {
        path:'/aboutImg',
        name:"aboutImg",
        components:{
          default:aboutImg,
          rightbody:aboutContent
        },
        meta: {
          title:"关于我-FQZの客栈"
        }
      },
      /* 留言板 */
      {
        path:'/messageImg',
        name:"messageImg",
        components:{
          default:messageImg,
          rightbody:messageContent
        },
        meta: {
          title:"留言板-FQZの客栈"
        }
      },
      /* 文章 */
      {
        path:'/articleImg',
        name:"articleImg",
        components:{
          default:articleImg,
          rightbody:articleContent
        },
        meta: {
          title:"文章-FQZの客栈"
        }
      },
      /* 文章内容*/
      {
        path:"/blogpostImg/:id",
        name:"blogpostImg",
        components:{
          default:blogpostImg,
          rightbody:blogpostContent
        },
      },
      /* 日志 */
      {
        path:"/journalImg",
        name:"journalImg",
        components:{
          default:journalImg,
          rightbody:journalContent
        },
        meta: {
          title:"时间轴-FQZの客栈"
        }
      },
      {
        path:'*',
        components:{
          default:headerImg,
          rightbody:defaultContent
        },
        meta: {
          title:"FQZの客栈"
        }
      },
    ]
  },
]




const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.meta.title){  //如果路由的title不为空则使用路由title
    document.title = to.meta.title
    next()
  }else{   //如果路由没有设置title则从vuex中获取
    document.title = router.app.$options.store.state.titleName
    next()
  }
})
export default router
